import React, { useEffect, useState } from 'react';
import './style.css';
import { ChakraProvider, Button } from "@chakra-ui/react"
import { Modal} from '../Modal'
import axios from 'axios';

const data = [

    {
        name: 'COLESTEROL FRACIONADO',
        codigo: 'COFRAN',
        descricao: "SANGUE",
        material: "SG"
    },
    {
        name: "COLESTEROL TOTAL",
        codigo: "COLN",
        descricao: "SANGUE",
        material: "SG"
    },
    {
        name: "GAMA GT",
        codigo: "GGT",
        descricao: "SANGUE",
        material: "SG"
    },
    {
        name: "GLICEMIA",
        codigo: "GLICO",
        descricao: "SANGUE",
        material: "SG"
    },
    {
        name: "HEMOGLOBINA GLICADA",
        codigo: "GLICHMDB",
        descricao: "SANGUE",
        material: "SG"
    },
    {
        name: "HEMOGRAMA",
        codigo: "HEPE2",
        descricao: "SANGUE",
        material: "SG"
    },
    {
        name: "TRIGLICERIDES",
        codigo: "TRIGLN",
        descricao: "SANGUE",
        material: "SG"
    }

]


export const ToDoList = () => {

    const [dataLeft, setDataLeft] = useState(data)

    const [dataRight, setDataRight] = useState([])

    const [modal, setModal] = useState(false)

    const handleChangeRight = (dataLeftProps) => {
        setDataRight((prev) => ([...prev, dataLeftProps]))
        dataLeft.splice(dataLeft.indexOf(dataLeftProps), 1);
    }

    const handleChangeLeft = (dataRightProps) => {
        setDataLeft((prev) => ([...prev, dataRightProps]))
        dataRight.splice(dataRight.indexOf(dataRightProps), 1);
    }

    const handleSubmit = async (dataRightProps) => {
        setModal(true);
      
       let schedule = await JSON.parse(localStorage.getItem("labsp/schedule"));
       const exames = Object.assign(schedule,{exames: dataRightProps });
       localStorage.setItem('labsp/schedule', JSON.stringify(exames));
       
    
    }

    const sortBy = (objectProps) => {
        return objectProps.sort((a, b) => {
            return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
        })
    }

   const  handleModal = ()=>{
        setModal(false)
    }

    const loading = ()=>{
        window.location.reload(true)
    }

    const modalCheck = (
        <>
            <Modal handleModal={handleModal} data={handleSubmit}/>
        </>
    )

    return (
        <>
           {!modal ? <ChakraProvider>
                <h1 style={{textAlign:'center', marginBottom:-26, fontSize:22}}>Agora basta selecionar abaixo, os exames a serem realizados pelo beneficiário para confirmar a geração da ordem de serviço.</h1>
                <div className={'root'}>
                    <div className={'main'}>
                       
                        <div className={'container2'}>
                        <h1>LISTA DE EXAMES</h1>
                        <div className={'container'}>
                            
                            {sortBy(dataLeft).map((value, i) => {
                                return <Button colorScheme="facebook" className={'buttonSelected'} onClick={() => handleChangeRight(dataLeft[i])} key={value.codigo}>
                                    {value.name}
                                </Button>
                            })}
                        </div>
                        </div>
                        
                        <div className={'container2'}>
                        <h1>EXAMES SELECIONADOS</h1>
                        <div className={'container'}>
                        
                            {sortBy(dataRight).map((value, i) => {
                                return <Button colorScheme="facebook" className={'buttonSelected'} onClick={() => handleChangeLeft(dataRight[i])} key={value.codigo}>
                                    {value.name || ""}
                                </Button>
                            })}
                        </div>
                        </div>
                    </div>
                <div>
                <Button onClick={() => loading()} colorScheme="facebook" className={'buttonSelected'} >
                       Voltar
                            </Button>
                <Button onClick={() => handleSubmit(dataRight)} colorScheme="facebook" className={'buttonSelected'} >
                        Gerar OS
                            </Button>
                    
                </div>
                </div>
            </ChakraProvider> : modalCheck}
        </>
    )
}
